import React from "react";
import "../scss/global.scss";
import SectionIntro from "./SectionIntro";

export default function IntroVideo({ title, subTitle, videoSrc, videoTitle, children }) {
  return (
    <section id="introVideo" className={"intro-video"}>
      <div className="container">
        <SectionIntro>
          {title && <h2>{title}</h2>}
          {subTitle && <p>{subTitle}</p>}
        </SectionIntro>
        <div className="vimeo-module">
          <iframe
            src={videoSrc}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title={videoTitle}
          ></iframe>
        </div>
        {children}
      </div>
    </section>
  );
}
