import React from "react";
import IntroVideo from "./IntroVideo";

export default function FeaturedVideos({ isAboutPage = false }) {
  const aboutVideoSubtitle = "Why you should partner with Performance Energy";
  return (
    <>
      <IntroVideo
        title={!isAboutPage ? "About us" : aboutVideoSubtitle}
        subTitle={!isAboutPage ? aboutVideoSubtitle : null}
        videoSrc="https://player.vimeo.com/video/1015665745?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        videoTitle="About us, why you should partner with Performance Energy"
      >
        <div className="double-button introVideoCta">
          {!isAboutPage && (
            <a
              className={`button primary arrow-light`}
              href="/about-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Learn more</span>
            </a>
          )}
          <a
            className={`button outline arrow`}
            href="../../brochures/performance-energy_Oil-Gas-Brochure-2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Download Brochure</span>
          </a>
        </div>
      </IntroVideo>
      <IntroVideo
        title="Saudi Arabia"
        subTitle="One vision, one mission"
        videoSrc="https://player.vimeo.com/video/1016037851?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        videoTitle="Saudi Arabia, One vision, one mission."
      >
        <div className="buttonCta introVideoCta">
          <a
            className={`button outline arrow`}
            href="../../brochures/performance-energy_Saudi-Arabia-Brochure-2024.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Download Brochure</span>
          </a>
        </div>
      </IntroVideo>
    </>
  );
}
