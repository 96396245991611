import * as React from "react";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import IntroVideo from "../../components/IntroVideo";
import OurImpact from "../../components/OurImpact";
import Testimonials from "../../components/testimonials/Testimonials";
import Brochures from "../../components/Brochures";
import FeaturedVideos from "../../components/FeaturedVideos";

const bannerImg = "../../banners/about-us_banner.jpg";

//RENDER
export default function AboutUsPage() {
  return (
    <Layout pageTitle={"About Us"}>
      <SplashBanner
        title={"About us"}
        content={
          "A truly unique personnel supply company dedicated to improving performance and safety standards across the Energy Industry."
        }
        backgroundImg={bannerImg}
      />

      {/*Intro video section*/}
     <FeaturedVideos isAboutPage={true} />

      {/*Our Impact*/}
      <OurImpact />

      {/*Testimonials*/}
      <Testimonials />

      {/*Brochures*/}
      <Brochures />
    </Layout>
  );
}
